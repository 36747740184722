import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const config_Farm = {
  apiKey: 'AIzaSyCQfayCFJEPJycRSi1KeCdtZPYNiVu9Ep0', //YOUR_PROJECT_API_KEY
  authDomain: 'merliniumfarm.firebaseapp.com',
  databaseURL: 'https://merliniumfarm.firebaseio.com',
  projectId: 'merliniumfarm', //YOUR_PROJECT_ID
  storageBucket: 'merliniumfarm.appspot.com',
  messagingSenderId: '479714979282' //YOUR_SENDER_ID
};

const config_Camera = {
  apiKey: 'AIzaSyBFWEGzJsB-UKE4nTP4gCuYA8dMcbWywFw', //YOUR_PROJECT_API_KEY
  authDomain: 'mytest-cf08c.firebaseapp.com',
  databaseURL: 'https://mytest-cf08c.firebaseio.com',
  projectId: 'mytest-cf08c', //YOUR_PROJECT_ID
  storageBucket: 'mytest-cf08c.appspot.com',
  messagingSenderId: '93374176020' //YOUR_SENDER_ID
};


firebase.initializeApp(config_Farm);
export const dbSensor = firebase.firestore();
export const storageImage = firebase.storage().ref();


var otherProject = firebase.initializeApp(config_Camera, 'other');
export const dbCamera = otherProject.firestore();
export const storageCamera = otherProject.storage().ref()

/*const config_Test_CO2 = {
  apiKey: '', //YOUR_PROJECT_API_KEY
  authDomain: 'test-co2.firebaseapp.com',
  databaseURL: 'https://test-co2.firebaseio.com',
  projectId: 'test-co2', //YOUR_PROJECT_ID
  storageBucket: 'test-co2.appspot.com',
  messagingSenderId: '658707711655' //YOUR_SENDER_ID
};
var otherProject = firebase.initializeApp(config_Test_CO2, 'other');
export const dbSensor = otherProject.firestore();
export const storageImage = otherProject.storage().ref()*/

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
