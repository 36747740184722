import { createStore } from 'vuex'
import { dbSensor } from "@/main"
import { pad2, getDate, convertDateToConpare } from "@/utility/utility";

export default createStore({
  state: {
    // Main System
    Sidebar_drawer: true,

    // Data Config
    User: "",
    Dashboards: [],
    DevicePage: false,
    AnalysisPage: false,
    AIPage: false,

    // Application
    AnalysisSelect: []
  },
  getters: {
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    ADD_ANALYSIS_SELECT(state, payload) {
      if (state.AnalysisSelect.length == 8) {
        state.AnalysisSelect.shift();
      }
      state.AnalysisSelect.push(payload);
    },
    DEL_ANALYSIS_SELECT(state, payload) {
      for (let i = 0; i < state.AnalysisSelect.length; i++) {
        if (state.AnalysisSelect[i] == payload) {
          state.AnalysisSelect.splice(i, 1);
        }
      }
    },
    CLEAR_ANALYSIS_SELECT(state, payload) {
      state.AnalysisSelect = [];
    }
  },
  actions: {
    clearDataUser({state}) {
      state.User = "";
      state.Dashboards = [];
    },
    async getDataUser({state}, uid) {
      let res = await dbSensor.collection("/Users2").doc(uid).get();
      let data = res.data();
      let Dashboards = [];
      
      if (data.AllDashboard) {
        let res = await dbSensor.collection("/Dashboard2").get();
        res.forEach(doc => {
          Dashboards.push(doc.id)
        })
      }else {
        Dashboards = data.Dashboards;
      }
      state.User = data.User;
      state.Dashboards = Dashboards;
      state.DevicePage = data.DevicePage;
      state.AnalysisPage = data.AnalysisPage;
      state.AIPage = data.AIPage;
    },
    async getLastDate({}, payload) {
      let res = await dbSensor.collection("/" + payload.type).doc(payload.token).get();
      let data = res.data();

      return data;
    },
    setLastDate({}, payload) {
      dbSensor.collection("/" + payload.type).doc(payload.token).set({
        LastCall: getDate(),
        LastData: payload.date
      });
    },
    async getDataSensor({}, payload) {
      let res = await dbSensor.collection("/" + payload.type + "/" + payload.token + "/Data").doc(payload.date).get();
      let data = res.data();

      if (data == undefined) {
        return {
          success: false,
          data: []
        };
      }else {
        let data_sort_ts = [];
        for (let i in data) {
          if (payload.type == "SMT221") {
            let one_data = data[i].split(",");
            if (Number(one_data[1]) > 100) {
              continue;
            }
            data_sort_ts.push({
              ts: Number(i),
              temp: one_data[0],
              mosi: one_data[1],
            })
          }else if (payload.type == "VSA221") {
            let one_data = data[i].split(",");
            data_sort_ts.push({
              ts: Number(i),
              state: one_data[0],
              batt: one_data[1],
            })
          }else if (payload.type == "ATH221") {
            let one_data = data[i].split(",");
            data_sort_ts.push({
              ts: Number(i),
              temp: Number(one_data[0]),
              huni: Number(one_data[1]),
              Batt: Number(one_data[2]),
              rssi: one_data[3]
            })
          }else if (payload.type == "ATH222") {
            let one_data = data[i].split(",");
            data_sort_ts.push({
              ts: Number(i),
              temp: Number(one_data[0]),
              huni: Number(one_data[1]),
            })
          }else if (payload.type == "weather_station") {
            data_sort_ts.push({
              ts: Number(i),
              // temp: (Number(data[i].tempinf) - 32) * 5 / 9,
              temp: Number(data[i].tempinf),
              huni: Number(data[i].humidityin),
              Solar: (data[i].solarradiation)? Number(data[i].solarradiation):0,
              Rain: (data[i].eventrainin)? Number(data[i].eventrainin):0,
              WindSpeed: (data[i].windspeedmph)? Number(data[i].windspeedmph):0,
              WindDirection: (data[i].winddir)? Number(data[i].winddir):0,
              Batt: (data[i].wh65batt)? Number(data[i].wh65batt):0,
            })
          }
        }
        data_sort_ts.sort((a, b) => {return a.ts - b.ts});

        return {
          success: true,
          data: data_sort_ts
        }
      }
    },
  },
  modules: {
  }
})
